import { addDays, isAfter, isDate, parseISO } from 'date-fns'

const parseDateWireIL = (date: string) => {
  const parsedDate = parseISO(date + ' +02:00')
  return isDate(parsedDate) && !isNaN(parsedDate.getTime()) ? parsedDate : parseISO(date)
}

export function hasDatePassedIL(dateString: string | undefined | null) {
  return !!(dateString && isAfter(new Date(), addDays(parseDateWireIL(dateString), 1)))
}
